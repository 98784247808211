import {
    Container,
    CssBaseline,
    Divider,
    Grid,
    ThemeProvider,
    Typography,
} from "@mui/material";
import { Contact } from "Contact";
import { HomeImage } from "HomeImage";
import { Library } from "Library";
import { Partners } from "Partners";
import { Helmet } from "react-helmet";
import { theme } from "theme";
import { Align } from "ui/Align";
import { Link } from "ui/Link";
import { Footer } from "../Footer";
import { Header } from "../Header";
import React from "react";

export default function Index() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Helmet
                htmlAttributes={{
                    lang: "en",
                }}
            >
                <meta charSet="utf-8" />
                <title>
                    QPED: Quality-focussed Programming Education: Intellectual
                    Output 2
                </title>
                <meta
                    name="description"
                    content="QPED: Quality-focussed Programming Education - Intellectual Output 2"
                />
                <link rel="canonical" href="https://www.qped.eu" />
            </Helmet>
            <Header />
            <HomeImage title="Intellectual Output 2: Procedural guidance" />
            {/* Hero unit */}
            <Container
                id="about"
                maxWidth="md"
                component="main"
                sx={{
                    paddingTop: 8,
                    paddingLeft: 2,
                    paddingRight: 2,
                    paddingBottom: 6,
                }}
            >
                <Typography color="textSecondary" gutterBottom>
                    Developing and testing software is a complex activity. A
                    procedure has been developed to design and specify
                    object-oriented (OO) software step by step. A distinction is
                    made between three different parts, namely an External View
                    (also known as API), an Internal View (concerning attributes
                    and private methods), and an Annotated Code View (the
                    working code). Each of these parts are made up of three
                    steps, namely Analysis (collecting all the information
                    needed to produce the design and specification), Design (in
                    which the structure is determined), and Specification (in
                    which the behaviour is described). The External View, the
                    Internal View and the Annotated Code View are then
                    translated step-by-step into the necessary tests. Explicit
                    guidance for each step is provided.{" "}
                </Typography>
                <Typography color="textSecondary" gutterBottom>
                    The procedure not only supports the development of the core
                    functionality, but also caters for robustness. The procedure
                    allows for an iterative and incremental development of
                    software where it is easy to go back to an earlier step, but
                    it is also possible to choose one's own order of execution
                    of the different steps. The procedure does require that
                    eventually all views are checked for consistency: in the end
                    it is the implementation that must fulfil what is promised
                    by the API!{" "}
                </Typography>
                <Typography color="textSecondary" gutterBottom>
                    The procedure has been evaluated on two groups of students.
                    It became clear that the first group could not be motivated
                    to produce specifications before coding. Developing software
                    through trial and error was preferred. In a second study, we
                    gave the students the full External View of each class.
                    Students now appreciated the specifications, which was
                    reflected in the quality of the software: fewer errors in
                    the code and tests that found more errors. The evaluation
                    provided us with two important insights. First, giving
                    specifications is an important starting point. Students
                    learn to appreciate the specifications and will probably
                    draft them themselves for more complex issues. The procedure
                    helps them with that.
                </Typography>
                <Typography color="textSecondary" gutterBottom>
                    Secondly, we have to conclude that many requirements are not
                    clear at all without precise specification. Students give
                    their own interpretation to this, which stimulates
                    trial-and-error behaviour. We are conducting follow-up
                    research on both aspects.
                </Typography>
                <Typography variant="h4" gutterBottom>
                    How to apply in your education
                </Typography>
                <Typography color="textSecondary" gutterBottom>
                    The procedure can be taught in the following ways:
                    <ul>
                        <li>
                            The procedure can be taught as a whole as part of a
                            Software Engineering course. Such a course is
                            typically placed in a second or third study year and
                            requires prior knowledge and experience with
                            programming and testing.
                        </li>
                        <li>
                            Parts of the procedure can be distributed over
                            several courses. Examples are:
                            <ul>
                                <li>
                                    Functions are discussed in an introductory
                                    programming course. In addition to teaching
                                    the syntax and the use of functions, the
                                    first column (External View) and the third
                                    column (Code View) can be applied from the
                                    procedure. These views indicate which
                                    aspects of a function can be distinguished,
                                    in which order they can be developed, how
                                    this leads to a design, specifications,
                                    implementation and associated tests, and
                                    documentation.
                                </li>
                                <li>
                                    In a first year OO programming course,
                                    classes and methods are introduced. Here the
                                    approach may be limited to developing an
                                    External View and Code View with associated
                                    tests. Attributes are added to classes, but
                                    describing them precisely in the form of an
                                    Internal View is postponed until a follow-up
                                    course (see the next bullet).
                                </li>
                                <li>
                                    In a course about data structures and
                                    algorithms, the Internal View is of special
                                    interest besides the External View and Code
                                    View. Here, the translation from domain
                                    variables to attributes and corresponding
                                    specifications is a critical activity.
                                </li>
                            </ul>
                        </li>
                    </ul>
                </Typography>
                <Typography color="textSecondary" gutterBottom>
                    The procedure itself is a complex thing for beginning
                    students. Learning the concepts underlying the procedure and
                    how to use the procedure cost time. It is important to
                    introduce the procedure using simple examples, so the
                    student has to deal with one complexity at the time. Once
                    the procedure is understood, it can be used to solve more
                    complex exercises. It is important to understand that the
                    use of the procedure is not a goal in itself; instead, it
                    provides guidance where the complexity is greater.
                </Typography>
                <Typography color="textSecondary" gutterBottom>
                    A good start is to give students the specifications from the
                    beginning, before they have to construct these by
                    themselves. In this approach, students learn to read the
                    specifications and experience the benefits of using them:
                    they have a grip on the implementation of the functionality,
                    can check whether all requirements have been met and can
                    draw up test cases more systematically. In a next step,
                    students learn how to draw up specifications using the
                    procedure. After that, more and more complex situations can
                    be solved. The procedure can be explained through
                    traditional classroom teaching and practiced by homework
                    assignments that students work on alone or in groups. The
                    procedure can also be taught by having the entire class, or
                    groups of students, work together on assignments and
                    frequently, after each procedural step, share their results
                    and discuss them. We think this approach is most effective.
                    Together, many more aspects are found that need to be taken
                    into account in a design and specification. It is precisely
                    this experience that is important! Worked-out solutions for
                    twenty exercises, ranging from simple to more complex ones,
                    have been provided. Several of these solutions are presented
                    in two versions: one where each step is worked out in detail
                    and all considerations are described, and one where only the
                    results of each step are recorded. The exercises and
                    solutions can be used in education.
                </Typography>
                <Typography variant="h4" gutterBottom>
                    Available material
                </Typography>
                <Typography color="textSecondary" gutterBottom>
                    <ul>
                        <li>
                            <a href="https://qped.eu/pg.pdf">
                                Technical report that describes the procedural
                                guidance in detail
                            </a>
                        </li>
                        <li>
                            The development of the{" "}
                            <a href="https://pro.quarterfall.com/do/96dktwhg">
                                power function
                            </a>{" "}
                            is an example of a small exercise and is implemented
                            in Quarterfall. The student has to develop the
                            function step-by-step in terms of the external view
                            as well as the code view. Because it is a function,
                            the internal view is not applicable here. The
                            student can view the results immediately after each
                            step.
                        </li>
                        <li>
                            Here are a few more examples of exercises that are
                            especially suitable to beginners:
                            <ul>
                                <li>
                                    <a href="https://pro.quarterfall.com/do/hcl1j6qq">
                                        Simple Temperature Convertor
                                    </a>
                                </li>
                                <li>
                                    <a href="https://pro.quarterfall.com/do/pgr2sd3v">
                                        Repeated Temperature Convertor
                                    </a>
                                </li>
                                <li>
                                    <a href="https://pro.quarterfall.com/do/8mlisubt">
                                        Repeated Temperature Convertor: Celsius
                                        to Fahrenheit and Vice-Versa
                                    </a>
                                </li>

                                <li>
                                    <a href="https://pro.quarterfall.com/do/myehpwta">
                                        Repeated Temperature Convertor: Celsius,
                                        Fahrenheit, and Kelvin
                                    </a>
                                </li>
                                <li>
                                    <a href="https://pro.quarterfall.com/do/9jss7u2s">
                                        Repeated Temperature Convertor: Abstract
                                        and Sub Classes
                                    </a>
                                </li>
                                <li>
                                    <a href="https://pro.quarterfall.com/do/agwn58bc">
                                        Repeated Temperature Convertor:
                                        Exceptions
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            A more extensive task is the development of{" "}
                            <a href="https://pro.quarterfall.com/do/j7qg0pcp">
                                class Bag
                            </a>
                            . The exercise is implemented in Quarterfall. The
                            student is given the specifications and has to
                            implement the class Bag and the necessary test cases
                            according to the specifications. The student submits
                            her/his implementation and test which are
                            automatically checked on correctness and test
                            completeness.
                        </li>

                        <li>
                            Lastly, the{" "}
                            <a href="https://qped.eu/minmax.pdf">MinMax</a>{" "}
                            class is an example of an exercise in which all
                            steps of the procedure are followed. Students who
                            has studied the whole procedure can make this
                            exercise. Teachers can use it as basic material to
                            develop an own assignment.
                        </li>
                        <li>
                            Note: more exercises are being worked on and will be
                            added here in the future.
                        </li>
                    </ul>
                </Typography>
            </Container>

            <Footer />
        </ThemeProvider>
    );
}
